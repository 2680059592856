import GlobalLoading from "./components/GlobalLoading";
import { configDocumentLinks } from "./configs/document";
import { themeCookie } from "./server/sessions.server";
import { Toaster } from "./shadcn/components/ui/toaster";
import { LinksFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import ThemeProvider, { Theme } from "~/components/ThemeProvider";
import { TooltipProvider } from "~/shadcn/components/ui/tooltip";

export type OutletContext = {
  state: "idle" | "loading" | "submitting";
};

// Links function to include the CSS
export const links: LinksFunction = () => {
  return configDocumentLinks;
};

// Meta function to include additional meta tags
export const meta: MetaFunction = () => {
  return [
    {
      charset: "utf-8",
      title: "Money Maker",
      viewport: "width=device-width,initial-scale=1",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const themeOrUndefined = await themeCookie.parse(request.headers.get("Cookie"));
  const theme = themeOrUndefined || "light";
  return { theme };
}

export default function App() {
  const { theme } = (useLoaderData() as { theme: Theme }) || { theme: "light" };
  const { state } = useNavigation();
  const outletContext = { state };

  return (
    <html lang="en" className={theme}>
      <head>
        <Meta />
        <Links />
      </head>
      <body className="text-simpleText bg-simpleFullPage">
        <ThemeProvider initialState={theme}>
          <TooltipProvider>
            <GlobalLoading>
              <Outlet context={outletContext} />
            </GlobalLoading>
            <Toaster />
          </TooltipProvider>
        </ThemeProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
